import request from "@/utils/request";

export function getStationList(data){
    return request({
        url: '/appreq/station/list',
        headers: {
            isToken: false
        },
        method: 'get',
        params:data
    })
}


//根据id查询类型
export function getStationById(id){
    return request({
        url: '/appreq/station/'+id,
        headers: {
            isToken: false
        },
        method: 'get',
    })
}

//获取主站的信息
export function getMainStationInfo(){
    return request({
        url: '/appreq/station/getMainStationInfo',
        headers: {
            isToken: false
        },
        method: 'get',
    })
}
