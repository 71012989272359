<template>
  <div class="container">
    <div
      class="container_block"
      v-show="!isempty"
    >
      <div
        class="container_block_item"
        @click="toDetail(item.id)"
        v-for="(item,index) in list"
        :key="index"
      >
        <div class="container_block_item_pic"><img
            :src="item.imgUrl?item.imgUrl:pic"
            alt=""
          ></div>
        <div class="container_block_item_title">{{item.name}}</div>
        <div class="container_block_item_addr"> {{item.address}}</div>
      </div>

    </div>
    <div
      class="container_page"
      v-show="!isempty"
    >
      <div class="container_page_wrap">
        <pagination
          v-show="total > 0"
          :total="total"
          v-model:page="query.pageNum"
          v-model:limit="query.pageSize"
          @pagination="getList"
        />
      </div>
    </div>
    <div
      class="container_empty"
      v-if="isempty"
    >
      <el-empty description="暂无数据" />
    </div>
    <div
      class="container_rightBtn"
      v-show="false"
    >
      <el-select
        v-model="value"
        class="m-2"
        placeholder="山东"
        size="large"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select
        v-model="value2"
        class="m-2"
        placeholder="德州"
        size="large"
      >
        <el-option
          v-for="item in options2"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
  </div>
</template>

<script setup name="Stations">
import { ref } from "@vue/reactivity"
import { useRouter } from "vue-router"
import { getStationList } from "@/api/station";
import Pagination from '@/components/Pagination'

const isEmpty = ref(false)
const value = ref('')
const value2 = ref('')
const router = useRouter();
const query = ref({})
const list = ref([]);
query.value = { pageNum: 1, pageSize: 6 }
const total = ref(0)
const options = [
  {
    value: 'Option1',
    label: '山东',
  },
  {
    value: 'Option2',
    label: '河北',
  },
  {
    value: 'Option3',
    label: 'Option3',
  },
  {
    value: 'Option4',
    label: 'Option4',
  },
  {
    value: 'Option5',
    label: 'Option5',
  },
]
const options2 = [
  {
    value: 'Option1',
    label: '德州',
  },
  {
    value: 'Option2',
    label: '河北',
  },
  {
    value: 'Option3',
    label: 'Option3',
  },
  {
    value: 'Option4',
    label: 'Option4',
  },
  {
    value: 'Option5',
    label: 'Option5',
  },
]

const toDetail = (id) => {
  router.push({
    path: "stationDetail", query: {
      id
    },
  })
}

const getList = async () => {
  let result = await getStationList(query.value);
  if (result?.code === 200) {
    list.value = result?.rows;
    isEmpty.value = false;
    total.value = result.total
  } else if (result?.code === 410) {
    list.value = [];
    isEmpty.value = true;
  } else {
    isEmpty.value = true;
  }
};

getList()
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #f5f5f5;
  padding-top: 30px;

  &_page {
    //background: #fff
    position: fixed;
    background: #fff;
    bottom: 0px;
    width: 100%;
    &_wrap {
      display: flex;
      justify-content: center;
    }
  }
  &_rightBtn {
    position: fixed;
    top: 110px;
    right: 0;
    display: flex;
    flex-direction: column;
  }
  &_block {
    margin: 0 auto;
    padding: 0 30px 30px 30px;
    width: 65%;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 100px;
    &_item {
      //display: flex;
      //flex-direction: column;
      text-align: left;
      background-color: #fff;
      padding: 30px 30px 0px 30px;
      border-radius: 6px;
      margin-right: 30px;
      margin-bottom: 30px;
      //height: 340px;
      &_pic {
      }
      &_pic img {
        width: 320px;
        height: 213px;
      }
      &_title {
        margin-top: 30px;
        font-size: 20px;
        font-weight: bold;
        color: #212121;
      }
      &_addr {
        text-align: left;
        width: 320px;
        margin-top: 40px;
        font-size: 16px;
        color: #bdbdbd;
        min-height: 60px;
      }
    }
  }
  &_empty {
    width: 65%;
    padding: 0 30px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
:deep(.el-pagination.is-background .el-pager li:not(.is-disabled).is-active) {
  background-color: #ff9959;
}
//  :deep(.el-input__inner) {
//   height: 60px;
//   width: 130px;
//   border-radius: 10px;
//   margin-top: 1px;
// }
</style>